import {
  Box,
  Button,
  Flex,
  Skeleton,
  SkeletonText,
  Text,
} from '@chakra-ui/react'
import { ProfileLayout } from '../ProfileLayout'
import { useLocation, useNavigate } from 'react-router-dom'
import { useGetNotice } from 'common/api/domains/profile/profileQuery'
import { useRecoilValue } from 'recoil'
import { clientIsMobile } from 'state/common/atoms'
import { useQueryClient } from 'react-query'
// import { useOwnerAccount } from 'common/api/domains/common/commonQuery'

export function DetailNoticeInfo() {
  const isMobile = useRecoilValue(clientIsMobile)
  const queryClient = useQueryClient()

  const location = useLocation()
  const noticeId = location.state

  const { data: notice, isLoading } = useGetNotice(Number(noticeId))
  const navigate = useNavigate()
  // const { data: ownerAccount } = useOwnerAccount()
  const handleBackToList = () => {
    // 캐시 초기화 (해당 쿼리만)
    queryClient.removeQueries(['profile', 'notice', Number(noticeId)])
    navigate(-1) // 이전 페이지로 이동
  }

  return (
    <ProfileLayout pageName="공지사항">
      {/*공지사항상세*/}
      {isLoading ? (
        <Box px={3}>
          {/* 제목 Skeleton */}
          <Skeleton height="30px" width="50%" mb={2} />
          {/* 게시일 Skeleton */}
          <Skeleton height="20px" width="30%" mb={8} />
          {/* 내용 Skeleton */}
          <SkeletonText
            mt={4}
            noOfLines={4}
            spacing={3}
            skeletonHeight="20px"
          />
          {/* 버튼 Skeleton */}
          <Skeleton height="40px" width="80%" margin="auto" mt={8} />
        </Box>
      ) : (
        <Box px={3}>
          <Flex justifyContent="space-between" alignItems="center">
            <Text
              fontSize={20}
              fontWeight={600}
              display={'flex'}
              justifyContent={'center'}
            >
              {notice?.title}
            </Text>
            {/*공지사항 제목*/}
          </Flex>
          {/* 공지사항 게시일 */}
          <Text pb={8}>2024.01.11</Text>
          {/* 공지사항 내용 */}
          <Box
            display={'flex'}
            w={'100%'}
            flexDirection={'column'}
            alignContent={'center'}
            gap={8}
          >
            <Text textAlign={'start'}>{notice?.body}</Text>
            {/*목록*/}
            <Button
              width={'80%'}
              margin={'auto'}
              textColor={'#FFFFFF'}
              bgColor={'#0471E9'}
              variant={'outline'}
              onClick={handleBackToList}
            >
              목록보기
            </Button>
          </Box>
        </Box>
      )}
    </ProfileLayout>
  )
}
