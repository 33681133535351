import {
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  Button,
  HStack,
  Box,
  Link,
  Spinner,
} from '@chakra-ui/react'
import './css/NoticeListPanel.scss'
import { useNoticeListQuery } from 'common/api/domains/profile/profileQuery'
import { useNavigate } from 'react-router-dom'
import { useState, useEffect, useRef } from 'react'
import { NoticeModel } from 'domain/profile'
import { useRecoilValue } from 'recoil'
import { clientIsMobile } from 'state/common/atoms'

export function NoticeListPanel() {
  const navigate = useNavigate()

  const isMobile = useRecoilValue(clientIsMobile)
  const { data: noticeList } = useNoticeListQuery()

  // 페이지네이션 상태
  const [currentPage, setCurrentPage] = useState(1)
  const [currentPageGroup, setCurrentPageGroup] = useState(1) // 페이지 그룹
  const [visibleNotices, setVisibleNotices] = useState<NoticeModel[]>([])
  const [hasMore, setHasMore] = useState(true)
  const itemsPerPage = 10

  const observerRef = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    if (!isMobile && noticeList) {
      // Initialize notices for non-mobile view
      const initialNotices = noticeList.slice(0, currentPage * itemsPerPage)
      setVisibleNotices(initialNotices)
    }
  }, [isMobile, noticeList, currentPage])

  useEffect(() => {
    if (isMobile && noticeList) {
      // Infinite scroll observer
      const observer = new IntersectionObserver(
        (entries) => {
          if (entries[0].isIntersecting && hasMore) {
            const nextNotices = noticeList.slice(
              visibleNotices.length,
              visibleNotices.length + itemsPerPage,
            )
            if (nextNotices.length > 0) {
              setVisibleNotices((prev) => [...prev, ...nextNotices])
            } else {
              setHasMore(false)
            }
          }
        },
        { threshold: 1.0 },
      )

      if (observerRef.current) {
        observer.observe(observerRef.current)
      }

      return () => observer.disconnect()
    }
  }, [isMobile, visibleNotices, noticeList, hasMore])

  // 총 페이지 수 계산
  const totalPages = noticeList
    ? Math.ceil(noticeList.length / itemsPerPage)
    : 1

  // 한 그룹에 표시할 페이지 수
  const pagesPerGroup = 5

  // 현재 페이지 그룹의 시작과 끝 페이지 번호 계산
  const startPage = (currentPageGroup - 1) * pagesPerGroup + 1
  const endPage = Math.min(startPage + pagesPerGroup - 1, totalPages)

  // 페이지 변경 함수
  const handlePageChange = (page: number) => {
    if (page > 0 && page <= totalPages) {
      setCurrentPage(page)
    }
  }

  // 페이지 그룹 변경 함수
  const handlePageGroupChange = (direction: 'next' | 'prev') => {
    if (direction === 'next' && currentPageGroup * pagesPerGroup < totalPages) {
      setCurrentPageGroup(currentPageGroup + 1)
    }
    if (direction === 'prev' && currentPageGroup > 1) {
      setCurrentPageGroup(currentPageGroup - 1)
    }
  }

  const showDetailNoticeInfo = (notice: number) => {
    navigate('/management/profile/notice/detail', { state: notice })
  }

  return (
    <>
      {isMobile ? (
        <TableContainer
          w={'100%'}
          overflow={'hidden'}
          textOverflow={'ellipsis'}
        >
          <Table size="sm">
            <Tbody>
              {visibleNotices && visibleNotices.length > 0 ? (
                visibleNotices.map((notice, idx) => (
                  <Tr
                    key={notice.id}
                    className={isMobile ? 'mobile-tr' : ''}
                    borderBottom="1px solid #6C6C6C"
                  >
                    <Td>
                      <Link
                        display="block"
                        onClick={() => showDetailNoticeInfo(Number(notice.id))}
                      >
                        <Text fontSize="sm" color="gray.600">
                          {'2025.01.11'}
                          {/* {notice.createdAt ? viewFormat(notice.createdAt) : ''} */}
                        </Text>
                        <Text
                          whiteSpace="normal" // Allow line breaking
                          overflow="hidden"
                          textOverflow="ellipsis"
                          noOfLines={2}
                        >
                          {notice?.title}
                        </Text>
                      </Link>
                    </Td>
                  </Tr>
                ))
              ) : (
                <Tr
                  className={isMobile ? 'mobile-tr' : ''}
                  borderBottom="1px solid #E2E8F0"
                >
                  <Td colSpan={3} textAlign={'center'}>
                    공지사항이 없습니다.
                  </Td>
                </Tr>
              )}
            </Tbody>
          </Table>
          <Box ref={observerRef} h={'32px'} />
          {hasMore && <Spinner display="block" mx="auto" mt={4} />}
        </TableContainer>
      ) : (
        <TableContainer w={'100%'}>
          <Table size="sm">
            <Thead>
              <Tr className={isMobile ? 'mobile-tr' : ''}>
                <Th textAlign={'center'}>번호</Th>
                <Th textAlign={'center'}>제목</Th>
                <Th textAlign={'center'}>등록일</Th>
              </Tr>
            </Thead>
            <Tbody>
              {visibleNotices && visibleNotices.length > 0 ? (
                visibleNotices.map((notice, idx) => (
                  <Tr className={isMobile ? 'mobile-tr' : ''} key={notice.id}>
                    <Td textAlign={'center'} py={3}>
                      <Text>{notice.id}</Text>
                    </Td>
                    <Td textAlign={'center'} py={3}>
                      <Link
                        onClick={() => showDetailNoticeInfo(Number(notice.id))}
                      >
                        {notice?.title}
                      </Link>
                    </Td>
                    <Td textAlign={'center'} py={3}>
                      <Text>
                        {'2025.01.11'}
                        {/* {notice.createdAt ? viewFormat(notice.createdAt) : ''} */}
                      </Text>
                    </Td>
                  </Tr>
                ))
              ) : (
                <Tr className={isMobile ? 'mobile-tr' : ''}>
                  <Td colSpan={3} textAlign={'center'}>
                    공지사항이 없습니다.
                  </Td>
                </Tr>
              )}
            </Tbody>
          </Table>
          <Box h={'32px'} />
          {/* 페이지네이션 버튼 */}
          {noticeList && noticeList.length > itemsPerPage && (
            <HStack justify="center" spacing={4} mt={4}>
              <Button
                onClick={() => handlePageGroupChange('prev')}
                disabled={currentPageGroup === 1}
              >
                &lt; {/* 화살표 하나 */}
              </Button>

              {/* 페이지 번호 버튼 */}
              {Array.from(
                { length: endPage - startPage + 1 },
                (_, idx) => startPage + idx,
              ).map((page) => (
                <Button
                  key={page}
                  onClick={() => handlePageChange(page)}
                  variant={currentPage === page ? 'solid' : 'outline'}
                >
                  {page}
                </Button>
              ))}

              <Button
                onClick={() => handlePageGroupChange('next')}
                disabled={currentPageGroup * pagesPerGroup >= totalPages}
              >
                &gt; {/* 화살표 하나 */}
              </Button>
            </HStack>
          )}
        </TableContainer>
      )}
    </>
  )
}
