import { Box, Grid, GridItem, Text } from '@chakra-ui/react'
import React from 'react'
import { ProductItem } from './ProductItem'
import { Link, useNavigate } from 'react-router-dom'
import {
  useAllProductListQuery,
  useProductListQuery,
} from 'common/api/domains/goods/goodsQuery'
import ProductCarousel from 'views/goods/ProductCarousel'
import { useGetNotice } from 'common/api/domains/profile/profileQuery'

export const CategoryListMobile = () => {
  const navigate = useNavigate()

  const handleNavigate = () => {
    navigate('/management/profile/notice/detail', { state: 1 })
  }
  const { data: products } = useAllProductListQuery()
  const { data: latestNotice } = useGetNotice(1)

  return (
    <Box
      w={'100%'}
      display={'flex'}
      justifyContent={'center'}
      flexDirection={'column'}
      alignItems={'center'}
    >
      <Box w={'375px'}>
        <Text my={4} fontSize={20} fontWeight={'bold'}></Text>
      </Box>
      {/* 상품 슬라이더 */}
      <ProductCarousel />
      <Box h={'32px'} />

      <Text
        fontSize={14}
        textAlign={'start'}
        onClick={handleNavigate}
        cursor="pointer"
      >
        {latestNotice?.title}
      </Text>
      <Box h={'32px'} />
      <Grid
        templateColumns={'repeat(auto-fill, 163px)'}
        gap={3}
        justifyContent={'center'}
      >
        {products &&
          products
            .filter((product) => product.is_main_display)
            .sort((a, b) => a.main_seq - b.main_seq)
            .map((product, index) => (
              <GridItem key={index}>
                <Link to={`/goods/products/${product.id}`}>
                  <ProductItem product={product} />
                </Link>
              </GridItem>
            ))}
      </Grid>
    </Box>
  )
}
