import { NoticeListPanel } from 'views/component/NoticeListPanel'
import { ProfileLayout } from '../ProfileLayout'

export function NoticeList() {
  return (
    <ProfileLayout pageName="공지사항">
      {/*공지사항 목록*/}
      <NoticeListPanel />
    </ProfileLayout>
  )
}
