import { useMutation, useQuery } from 'react-query'
import {
  ChangePasswordFetcher,
  UserInfoModifyFetcher,
  deleteAddressFetcher,
  deleteChangeDefaultAddressFetcher,
  fetchNoticeList,
  fetchOrderList,
  getMyDeliveryAddressFetcher,
  getNoticeFetcher,
  getOrderFetcher,
  getRecentShoppingInfoFetcher,
} from '../../fetcher/profile'
import {
  ChangePaswordDto,
  ProfileModifyDto,
  UserAddressProps,
} from 'domain/profile'
import { addAddressFetcher } from 'state/profile/atoms'
import { fetchNotice } from 'common/api/fetcher/profile/quries'

export default function profileQuery() {
  // todo
}

// export const useLogoutQuery = () => {
//   return useMutation((data: boolean) => logoutFetcher(data))
// }
export const useRecentShoppingInfoQuery = () => {
  const { data, isLoading, error, refetch } = useQuery(
    ['profile', 'shopping', 'recent'],
    () => getRecentShoppingInfoFetcher(),
  )

  return { data, isLoading, error, refetch }
}

export const useAddOrModifyAddressQuery = () => {
  return useMutation((data: UserAddressProps) => addAddressFetcher(data))
}
export const useMyDeliveryAddressQuery = () =>
  useQuery(['profile', 'delivery'], () => getMyDeliveryAddressFetcher())

export const useChangePasswordQuery = () => {
  return useMutation((data: ChangePaswordDto) => ChangePasswordFetcher(data))
}
export const useUserInfoModifyQuery = () => {
  return useMutation((data: ProfileModifyDto) => UserInfoModifyFetcher(data))
}

export const useDeleteAddressQuery = () => {
  return useMutation((data: Int32List) => deleteAddressFetcher(data))
}
export const useChangeDefaultAddressQuery = () => {
  return useMutation((addressId: number) =>
    deleteChangeDefaultAddressFetcher(addressId),
  )
}

export const useOrderListQuery = (startDate: string, endDate: string) => {
  return useQuery(['profile', 'shopping', 'recent'], () =>
    fetchOrderList(startDate, endDate),
  )
}

export const useGetOrderQuery = (orderId: number) =>
  useQuery(['profile', 'delivery'], () => getOrderFetcher(orderId))

export const useNoticeListQuery = () => {
  return useQuery(['profile', 'noticeList'], () => fetchNoticeList())
}

export const useGetNotice = (noticeId: number) =>
  useQuery(['profile', 'notice', noticeId], () => getNoticeFetcher(noticeId))
