import axios from 'axios'
import { createAxiosInstance } from 'common/config/axios.config'
import { CommonCode } from 'domain/common/model'
import { CarouselModel, CategoryModel, ProductModel } from 'domain/goods/model'
import { ProductResponseDTO } from 'domain/goods/response.dto'

const goodsAxiosInstance = createAxiosInstance(false)

export function fetchProduct(
  productId: number | string,
): Promise<ProductModel> {
  return goodsAxiosInstance
    .get<ProductResponseDTO>(`/goods/products/${productId}`)
    .then((response) => {
      return new ProductModel(response.data)
    })
}

export function fetchCategories(): Promise<CategoryModel[]> {
  return goodsAxiosInstance
    .get('/goods/categories')
    .then((response) => response.data)
}

export function fetchCategory(
  categoryId: number | string,
): Promise<CategoryModel> {
  return goodsAxiosInstance
    .get(`/goods/categories/${categoryId}`)
    .then((response) => response.data)
}

export function fetchAllProductList(): Promise<ProductModel[]> {
  return goodsAxiosInstance
    .get('/goods/products')
    .then((response) => response.data)
}

export function fetchProductList(
  categoryId: number | string,
): Promise<ProductModel[]> {
  return goodsAxiosInstance
    .get(`/goods/categories/${categoryId}/products`)
    .then((response) => response.data)
}

export function getCommonCodeFetch(): Promise<CommonCode[]> {
  return goodsAxiosInstance
    .get(`/common/product`)
    .then((response) => response.data)
}

export async function fetchCarousel(
  itemLength: number,
): Promise<CarouselModel[]> {
  const response = await axios.get('https://picsum.photos/v2/list', {
    params: {
      page: 1,
      limit: itemLength,
    },
  })
  return response.data.map((item: any) => item)
  // return goodsAxiosInstance
  //   .get(`/goods/carousel`)
  //   .then((response) => response.data)
}
