// import { useAuth } from '@/components';
import PublicRoutes from './PublicRoutes'
import Footer from 'views/component/Footer'
import WithSubnavigation from 'views/component/Navbar'
import { Container, Flex, Box } from '@chakra-ui/react'
import PrivateRoutes from './PrivateRoutes'
import { Route, Routes, useLocation } from 'react-router-dom'
import { useRecoilValue } from 'recoil'
import { clientIsMobile } from 'state/common/atoms'
export const AppRoutes = () => {
  const { pathname } = useLocation()
  const isHome = pathname === '/'
  const isMobile = useRecoilValue(clientIsMobile)

  return (
    <>
      <Flex flexDirection="column" height="100vh">
        <WithSubnavigation />
        <Flex
          flex={1}
          alignItems={isMobile ? 'start' : undefined}
          justifyContent={isMobile ? 'center' : undefined}
        >
          <Container
            maxW={isMobile ? '375px' : isHome ? '100vw' : '1200px'}
            p={0}
            mb={isMobile ? 4 : 8}
          >
            <Routes>
              <Route path="/*" element={<PublicRoutes />} />
              <Route path="/users/:userId/*" element={<PrivateRoutes />} />
              <Route path="/management/*" element={<PrivateRoutes />} />
            </Routes>
          </Container>
        </Flex>
        <Footer />
      </Flex>
    </>
  )
}
