import {
  AbsoluteCenter,
  Box,
  Button,
  Center,
  Container,
  Divider,
  Flex,
  Grid,
  GridItem,
  Input,
  SimpleGrid,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Table,
  TableContainer,
  Tabs,
  Tbody,
  Td,
  Text,
  Tfoot,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react'
import React, { useEffect } from 'react'
import { ProfileLayout } from './ProfileLayout'
import { ChevronRightIcon } from '@chakra-ui/icons'
import { CustomInput } from 'views/component/CustomInput'
import { OrderListPanel } from 'views/component/OrderListPanel'
import { OrderModel } from 'domain/purchase/models'
import { useLocation, useNavigate } from 'react-router-dom'
import './css/orderDetail.scss'
import { OrderStatusKorean } from 'common/util/enum'
import { formatDateString } from 'common/util/string-utils'
import { useGetOrder } from 'common/api/domains/purchase/purchaseQuery'
import {
  useGetOrderQuery,
  useRecentShoppingInfoQuery,
} from 'common/api/domains/profile/profileQuery'
import { useRecoilValue } from 'recoil'
import { clientIsMobile } from 'state/common/atoms'
import { OrderReceiptBase } from 'views/component/OrderReceiptBase'
import { usePrivateNavigate } from 'common/hooks/usePrivateNavigate'
import { useOwnerAccount } from 'common/api/domains/common/commonQuery'
const selectedStyle = {
  fontWeight: 'bold',
  color: 'black',
  borderTop: '2px solid black',
  borderBottom: 'none',
}
const inputStyle = {
  fontSize: '11px',
  height: '32px',
  padding: '5px',
}
export function DetailOrderInfo() {
  const isMobile = useRecoilValue(clientIsMobile)
  const navigate = useNavigate()

  const location = useLocation()
  const orderId = location.state
  const { data: order, refetch } = useGetOrder(Number(orderId))
  const { data: orderModel } = useGetOrderQuery(orderId.id)
  const { data: ownerAccount } = useOwnerAccount()

  return (
    <>
      <ProfileLayout pageName="ЗАХИАЛГЫН МЭДЭЭЛЭЛ">
        {/*주문내역상세*/}
        <div className="order-detail">
          <Flex justifyContent="space-between" alignItems="center">
            <Text as="b">МЭДЭЭЛЭЛ</Text> {/*주문정보*/}
            {/* <OrderReceiptBase
              orderId={orderModel?.id ? orderModel?.id : 1}
              // refetchOrder={async () => {
              //   await refetch()
              // }}
            /> */}
          </Flex>
          <Table mt={2} mb={5}>
            <Tbody>
              <Tr className={isMobile ? 'mobile-tr' : ''} fontSize={14}>
                <Td className={'title'}>ЗАХИАЛГЫН No.</Td> {/*주문번호*/}
                <Td className="data">{orderModel?.id}</Td>
              </Tr>
              <Tr className={isMobile ? 'mobile-tr' : ''} fontSize={14}>
                <Td className={'title'}>ЗАХИАЛСАН ӨДӨР</Td> {/*주문일자*/}
                <Td className="data">
                  <Text>
                    {orderModel?.createdAt
                      ? formatDateString(orderModel?.createdAt)
                      : ' '}
                  </Text>
                </Td>
              </Tr>
              <Tr className={isMobile ? 'mobile-tr' : ''} fontSize={14}>
                <Td className={'title'}>ЗАХИАЛАГЧ</Td> {/*주문자*/}
                <Td className="data"> {orderModel?.name}</Td>
              </Tr>
              <Tr className={isMobile ? 'mobile-tr' : ''} fontSize={14}>
                <Td className={'title'}>БАЙДАЛ</Td> {/*주문상태*/}
                <Td className="data">
                  {orderModel?.status
                    ? OrderStatusKorean[orderModel?.status]
                    : 'ERROR'}
                </Td>
              </Tr>
            </Tbody>
          </Table>
          <Text as="b">ТӨЛБӨР</Text> {/*결제정보*/}
          <Table mt={2} mb={5}>
            <Tbody>
              <Tr className={isMobile ? 'mobile-tr' : ''} fontSize={14}>
                <Td className={'title'}>НИЙТ ҮНЭ</Td> {/*총 결제금액*/}
                <Td className="data">
                  {orderModel?.orderProducts
                    ? orderModel?.orderProducts
                        .reduce(
                          (total, prod) =>
                            total + (prod.price || 0) * (prod.count || 0),
                          0,
                        )
                        .toLocaleString('en-us')
                    : 0}
                  원
                </Td>
              </Tr>
              <Tr className={isMobile ? 'mobile-tr' : ''} fontSize={14}>
                <Td className={'title'}>ТӨЛБӨРИЙН НӨХЦӨЛ</Td> {/*결제방법*/}
                <Td className="data">
                  <Text>ДАНС ШИЛЖҮҮЛЭХ</Text> {/*무통장입금*/}
                  <br />
                  {/* <Text>
                    계좌번호 : 하나은행 123456789123456789 | 입금자 :{' '}
                    {orderModel?.name}
                  </Text> */}
                  <Text as={'b'}>
                    ТӨЛБӨРИЙН ДАНС
                    <br />
                    {ownerAccount} <br />
                    <br /> ХАДГАЛАМЖ ЭЗЭМШИГЧ
                    <br /> {orderModel?.name}
                  </Text>
                </Td>
              </Tr>
            </Tbody>
          </Table>
          <Text as="b">БҮТЭЭГДЭХҮҮН</Text> {/*주문상품*/}
          <Table mt={2} mb={5}>
            <Tbody>
              <Tr fontSize={14}>
                <Td className={'title'}>БҮТЭЭГДЭХҮҮН</Td> {/*상품명*/}
                <Td className={'title'}>ҮНЭ/ШИРХЭГ</Td> {/*금액/수량*/}
              </Tr>
              {orderModel?.orderProducts &&
              Array.isArray(orderModel?.orderProducts)
                ? orderModel?.orderProducts.map((prod) => (
                    <Tr fontSize={14}>
                      <Td className="prod-title">{prod.productName}</Td>
                      <Td className="prod-data">
                        &#x20a9;
                        {(prod.count * prod.price).toLocaleString('en-us')}₩ /{' '}
                        {prod.count.toLocaleString('en-us')}
                      </Td>
                    </Tr>
                  ))
                : ''}
            </Tbody>
          </Table>
          <Text as="b">ХҮРГЭЛТ</Text> {/*배송지정보*/}
          <Table mt={2} mb={5}>
            <Tbody>
              <Tr className={isMobile ? 'mobile-tr' : ''} fontSize={14}>
                <Td className={'title'}>ХҮЛЭЭН АВАГЧ</Td> {/*수령인*/}
                <Td className="data">{orderModel?.depositorName}</Td>
              </Tr>
              <Tr className={isMobile ? 'mobile-tr' : ''} fontSize={14}>
                <Td className={'title'}>БҮСИЙН No.</Td> {/*우편번호*/}
                <Td className="data">{orderModel?.postcode}</Td>
              </Tr>
              <Tr className={isMobile ? 'mobile-tr' : ''} fontSize={14}>
                <Td className={'title'}>ХАЯГ</Td> {/*주소*/}
                <Td className="data">
                  {orderModel?.address1 && orderModel?.address2
                    ? orderModel?.address1 + '  ' + orderModel?.address2
                    : ''}
                </Td>
              </Tr>
              <Tr className={isMobile ? 'mobile-tr' : ''} fontSize={14}>
                <Td className={'title'}>УТАС</Td> {/*전화번호*/}
                <Td className="data">{orderModel?.phone}</Td>
              </Tr>
            </Tbody>
          </Table>
          <Box textAlign={'center'}>
            <Button
              width={'80%'}
              variant={'outline'}
              onClick={() => {
                navigate(-1)
              }}
            >
              ЗАХИАЛГЫН ЖАГСААЛТ
            </Button>{' '}
            {/*목록*/}
          </Box>
        </div>
      </ProfileLayout>
    </>
  )
}
