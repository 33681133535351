import { NoticeResponseDTO } from './user/response.dto'

export class Profile {}

export type UserAddressProps = {
  id?: number
  name?: string
  postcode?: string
  address1?: string
  address2?: string
  phone?: string
}

export type ChangePaswordDto = {
  newPassword: string
  prevPassword: string
}

export type ProfileModifyDto = {
  newUsername: string
  newPhone: string
}

export class NoticeModel {
  userId: number
  id: number
  title: string
  body: string

  constructor({ userId, id, title, body }: NoticeResponseDTO) {
    this.userId = userId
    this.id = id
    this.title = title
    this.body = body
  }
}
