import { useRef } from 'react'
import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Navigation } from 'swiper/modules'
import SwiperCore from 'swiper'
import 'swiper/css'
import 'swiper/css/navigation'
import { useCarouselListQuery } from 'common/api/domains/goods/goodsQuery'

interface ProductCardsType {
  id: string
  author: string
  width: number
  height: number
  url: string
  download_url: string
}

export default function ProductCards() {
  const swiperRef = useRef<SwiperCore>()
  const { data: cards } = useCarouselListQuery(10)

  return (
    <div className="container-box">
      {cards && cards.length > 0 ? (
        <div className="small-container">
          <Swiper
            onBeforeInit={(swiper) => {
              swiperRef.current = swiper
            }}
            navigation={{ prevEl: '.arrow-left', nextEl: '.arrow-right' }}
            modules={[Navigation]}
            slidesPerView={1}
            spaceBetween={10}
            breakpoints={{
              375: {
                slidesPerView: 2.5,
                spaceBetween: 10,
              },
              800: {
                slidesPerView: 3,
                spaceBetween: 20,
              },
              1200: {
                slidesPerView: 3,
                spaceBetween: 25,
              },
              1600: {
                slidesPerView: 4,
                spaceBetween: 30,
              },
              1920: {
                slidesPerView: 5,
                spaceBetween: 35,
              },
            }}
          >
            {cards.map((data: ProductCardsType) => (
              <SwiperSlide key={data.id}>
                <div className="flex flex-col w-52 h-72 border border-black">
                  <img className="h-30" src={data.download_url} alt="thumb" />
                  <p className="flex justify-center mt-7 text-lg font-bold">
                    {data.author}
                  </p>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
          <ChevronLeftIcon className="arrow-left arrow" w={50} h={50} />
          <ChevronRightIcon className="arrow-right arrow" w={50} h={50} />
        </div>
      ) : null}
    </div>
  )
}
