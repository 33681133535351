import { Box, Grid, GridItem, Text } from '@chakra-ui/react'
import { ProductItem } from './ProductItem'
import { Link, useNavigate } from 'react-router-dom'
import { useAllProductListQuery } from 'common/api/domains/goods/goodsQuery'
import ProductCarousel from 'views/goods/ProductCarousel'
import { useGetNotice } from 'common/api/domains/profile/profileQuery'

export const CategoryListDesktop = () => {
  const navigate = useNavigate()

  const handleNavigate = () => {
    navigate('/management/profile/notice/detail', { state: 1 })
  }

  const { data: products } = useAllProductListQuery()
  const { data: latestNotice } = useGetNotice(1)

  return (
    <Box
      w={'100%'}
      display={'flex'}
      justifyContent={'center'}
      flexDirection={'column'}
      alignItems={'center'}
    >
      {/* 상품 슬라이더 */}
      <ProductCarousel />
      <Box h={'32px'} />
      <Box w={'1200px'}>
        <Text
          ml={5}
          fontSize={14}
          textAlign={'start'}
          onClick={handleNavigate}
          cursor="pointer"
        >
          {latestNotice?.title}
        </Text>
        {/* <Text ml={5} textAlign={'start'} fontSize={14}>
          {latestNotice?.title}
        </Text> */}
      </Box>
      <Box h={'32px'} />
      <Box w={'1200px'}>
        <Text fontSize={20} fontWeight={'bold'} my={8} ml={5}>
          БҮТЭЭГДЭХҮҮН
        </Text>
      </Box>
      <Box h={'64px'} />
      {/*상품*/}
      <Grid
        w={'1200px'}
        templateColumns={'repeat(auto-fill, 272px)'}
        gap={'26px'}
        justifyContent={'center'}
      >
        {products &&
          products
            .filter((product) => product.is_main_display)
            .sort((a, b) => a.main_seq - b.main_seq)
            .map((product, index) => (
              <GridItem key={index}>
                <Link to={`/goods/products/${product.id}`}>
                  <ProductItem product={product} />
                </Link>
              </GridItem>
            ))}
      </Grid>
    </Box>
  )
}
