import React, { useRef, useState, useEffect } from 'react'
import { Box, IconButton, useMediaQuery } from '@chakra-ui/react'
import { FaPlay, FaPause } from 'react-icons/fa'
import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons'

import Slider, { Settings } from 'react-slick'
import { useCarouselListQuery } from 'common/api/domains/goods/goodsQuery'

export default function ProductCarousel() {
  const sliderRef = useRef<Slider | null>(null)
  const [isPlaying, setIsPlaying] = useState(true)
  const [isLargerThanMd] = useMediaQuery('(min-width: 48em)')
  const [sliderHeight, setSliderHeight] = useState('500px')
  const [maxWidth, setMaxWidth] = useState('100%')

  useEffect(() => {
    if (isLargerThanMd) {
      setSliderHeight('500px')
      setMaxWidth('1200px')
    } else {
      setSliderHeight('300px')
      setMaxWidth('100%')
    }
  }, [isLargerThanMd])

  const { data: cards, isLoading } = useCarouselListQuery(5)
  const settings: Settings = {
    dots: true,
    arrows: true,
    infinite: true,
    autoplay: isPlaying,
    speed: 500,
    autoplaySpeed: 5000,
    slidesToShow: 1,
    slidesToScroll: 1,
    appendDots: (dots: any) => (
      <div>
        <ul>
          {dots}
          <li>
            <button
              className="flex items-center"
              onClick={() => setIsPlaying((prev) => !prev)}
            >
              {isPlaying ? (
                <img src="/mall1/pause.svg" alt="중지" className="w-3 h-3" />
              ) : (
                <img src="/mall1/play.svg" alt="재생" className="w-3 h-3" />
              )}
            </button>
          </li>
        </ul>
      </div>
    ),
    dotsClass: 'dots_custom',
  }

  if (isLoading) return <Box></Box>

  return (
    <Box
      position={'relative'}
      // maxW={maxWidth}
      // mx={'auto'}
      width={'100%'}
      overflow={'hidden'}
    >
      {/* 왼쪽 화살표 */}
      <IconButton
        aria-label="left-arrow"
        colorScheme="transparent"
        borderRadius="full"
        position="absolute"
        left={'10px'}
        top={'50%'}
        transform={'translate(0%, -50%)'}
        zIndex={2}
        onClick={() => sliderRef.current?.slickPrev()}
      >
        <ChevronLeftIcon boxSize={12} color={'#222'} />
      </IconButton>

      {/* 오른쪽 화살표 */}
      <IconButton
        aria-label="right-arrow"
        colorScheme="transparent"
        borderRadius="full"
        position="absolute"
        right={'10px'}
        top={'50%'}
        transform={'translate(0%, -50%)'}
        zIndex={2}
        onClick={() => sliderRef.current?.slickNext()}
      >
        <ChevronRightIcon boxSize={12} color={'#222'} />
      </IconButton>

      {/* 슬라이더 */}
      <Slider {...settings} ref={(slider) => (sliderRef.current = slider)}>
        {cards?.map((url, index) => (
          <Box
            key={index}
            height={sliderHeight}
            width="100%"
            backgroundPosition="center"
            backgroundRepeat="no-repeat"
            backgroundSize="cover"
            backgroundImage={`url(${url.download_url})`}
          />
        ))}
      </Slider>
    </Box>
  )
}
