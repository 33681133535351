import axios from 'axios'
import { createAxiosInstance } from 'common/config/axios.config'
import { OrderStatusCount, RecentlyShoppingInfo } from 'domain/goods'
import {
  ChangePaswordDto,
  NoticeModel,
  ProfileModifyDto,
  UserAddressProps,
} from 'domain/profile'
import { OrderModel } from 'domain/purchase/models'
import { OrderResponseDTO } from 'domain/purchase/response.dto'

const profileAxiosInstance = createAxiosInstance(true)

export function fetchProductDetail(productId: number | string) {
  return profileAxiosInstance
    .get(`/products/${productId}/detail`)
    .then((response) => response.data)
}

export function getRecentShoppingInfoFetcher(): Promise<RecentlyShoppingInfo> {
  return profileAxiosInstance
    .get<{ orderList?: OrderResponseDTO[]; status?: OrderStatusCount }>(
      `/profile/shopping/recent`,
    )
    .then((response) => {
      return {
        orderList: response.data.orderList?.map(
          (orderResponseDto) => new OrderModel(orderResponseDto),
        ),
        status: response.data.status,
      }
    })
    .catch((e) => e)
}

export async function getMyDeliveryAddressFetcher(): Promise<
  UserAddressProps[]
> {
  return await profileAxiosInstance
    .get(`/profile/address`)
    .then(async (response) => {
      return await response.data
    })
    .catch((e) => e)
}

export async function deleteAddressFetcher(data: Int32List) {
  return await profileAxiosInstance
    .post(`/profile/address/delete`, data)
    .then(async (response) => {
      return await response.data
    })
    .catch((e) => e)
}
export async function ChangePasswordFetcher(data: ChangePaswordDto) {
  return await profileAxiosInstance
    .put(`/profile/password/change`, data)
    .then(async (response) => {
      return await response.data
    })
}
export async function UserInfoModifyFetcher(data: ProfileModifyDto) {
  return await profileAxiosInstance
    .put(`/profile/modify`, data)
    .then(async (response) => {
      return await response.data
    })
}
export async function deleteChangeDefaultAddressFetcher(addressId: number) {
  return await profileAxiosInstance
    .put(`/profile/address/default`, { default_address_id: addressId })
    .then(async (response) => {
      return await response.data
    })
}

export function fetchOrderList(
  startDate: string,
  endDate: string,
): Promise<OrderModel[]> {
  return profileAxiosInstance
    .get<
      OrderResponseDTO[]
    >(`/profile/order?start-date=${startDate}&end-date=${endDate}`)
    .then((response) =>
      response.data.map((orderResponseDto) => new OrderModel(orderResponseDto)),
    )
}

export function getOrderFetcher(orderId: number): Promise<OrderModel> {
  return profileAxiosInstance
    .get<OrderResponseDTO>(`/profile/order/${orderId}`)
    .then((response) => new OrderModel(response.data))
}

export function fetchNoticeList(): Promise<NoticeModel[]> {
  return axios
    .get<NoticeModel[]>('https://jsonplaceholder.typicode.com/posts')
    .then((response) =>
      response.data.map(
        (noticeResponseDto) => new NoticeModel(noticeResponseDto),
      ),
    )
}

export function getNoticeFetcher(noticeId: number): Promise<NoticeModel> {
  return axios
    .get(`https://jsonplaceholder.typicode.com/posts/${noticeId}`)
    .then((response) => new NoticeModel(response.data))
}
